import { Fragment, useState } from 'react'
import { useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from '../../shared/Icon/Icon';
import type { RootState } from '../../../store/reducers/rootReducer';
import type { ValidationError } from '../../shared/Zod/handleZodValidation';
import type { batchSchema } from '../../shared/Zod/ZodSchemas/batchSchema';
import { Switch } from '@progress/kendo-react-inputs';
import { DateTime } from 'luxon';
import type { audienceSchema } from '../../shared/Zod/ZodSchemas/audienceSchema';
import { BatchAudienceInfo } from './BatchAudienceInfo';

type Props = {
  errors?: ValidationError<typeof batchSchema>,
  audienceErrors?: ValidationError<typeof audienceSchema>,
  audienceName?: string;
}

const currencies = [{ currency: "GBP", title: "GBP (£)" }, { currency: "USD", title: "USD ($)" }, { currency: "AUD", title: "AUD (A$)" }];

export const getCurrentDate = () => DateTime.now().toFormat('yyyy-LL-dd');

export const BatchSetupDetails = ({ errors, audienceErrors, audienceName }: Props) => {
  const batchData = useSelector((state: RootState) => state.batchDataReducer);
  const { audienceAction, displayName } = useSelector((state: RootState) => state.audienceDataReducer);
  const [selectedCurrency, setSelectedCurrency] = useState({ currency: batchData.currency ? batchData.currency : "GBP", name: batchData.currency ? currencies.find((item) => item.currency === batchData.currency)?.title : "GBP (£)" });
  const [selectedTargetType, setSelectedTargetType] = useState(batchData.targetType ? batchData.targetType : "Completes");
  const [isChecked, setIsChecked] = useState(batchData.completeAsap);
  const [launchDate, setLaunchDate] = useState(batchData.launchDate);
  const [targetEndDate, setTargetEndDate] = useState(batchData.targetEndDate);

  const isStatusClosedOrReconciled = audienceAction !== "copy" || batchData.batchAction !== "copy" ? batchData.status === "Closed" || batchData.status === "Reconciled" : false;

  return (
    <Fragment>
      <article className='nav-board bg-white d-flex justify-content-center flex-column w-100 p-5'>
        <p className='innerboard-header d-flex align-items-center stronger mb-2 h-32'>Batch setup details</p>

        {(audienceAction !== "copy" && batchData.batchAction !== "new") && <BatchAudienceInfo />}

        <input readOnly hidden defaultValue={batchData.market?.country} name='batchCountry' />
        <input readOnly hidden defaultValue={batchData.market?.language} name='batchLanguage' />

        <div className='board-content-container'>
          <div className='row row-col-2 px-1'>
            <div className="details-input d-flex flex-column w-50 gap-sm">
              <p className='p-0 m-0 strong details-input-text text-primary'>
                Batch name *
              </p>

              {audienceAction === "new" && batchData.batchAction === "new" && <input className='form-control' type="text" id='batchName' name='batchDisplayName' autoComplete='off' defaultValue={audienceName} />}
              {batchData.batchAction !== "new" && <input className='form-control' type="text" id='batchName' name='batchDisplayName' autoComplete='off' defaultValue={audienceAction === "copy" ? `${displayName} (1)` : batchData.displayName} />}

              {errors?.displayName &&
                <div className="text-danger">
                  <small>{errors?.displayName}</small>
                </div>
              }
            </div>

            <div className="details-input dropdown details-dropdown d-flex flex-column w-50 gap-sm">
              <p className='p-0 m-0 strong text-primary details-input-text'>
                Target type
              </p>

              <button
                className={"btn dropdown-toggle form-control"}
                type="button" id="dropdownMenuAction"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {selectedTargetType}
                <span>
                  <Icon type="chevron-mini-down-alt" className="submenu" />
                </span>
              </button>

              <div className="dropdown-menu menu-action" aria-labelledby="dropdownMenuAction">
                {["Completes", "Prescreens"].map((item) => (
                  <button key={item} className="dropdown-item" type="button" onClick={() => setSelectedTargetType(item)}>{item}</button>
                ))}
              </div>

              <input hidden readOnly name='targetType' value={selectedTargetType} />

              {
                (errors?.targetType || audienceErrors?.targetType) &&
                <div className="text-danger">
                  <small>{errors?.targetType}</small>
                  <small>{audienceErrors?.targetType}</small>
                </div>
              }
            </div>

            <div className='d-flex w-50'>
              <div className="details-input input-group d-flex flex-column w-50 gap-sm">
                <div className='d-flex flex-row justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <p className="p-0 m-0 strong text-primary details-input-text">
                      Sample CPI *
                    </p>

                    <Tooltip anchorElement="target" position="top">
                      <span className='icon cursor-pointer' title='Visible to Walr employees'>
                        <Icon type="info-tooltip" className='pe-none' />
                      </span>
                    </Tooltip>
                  </div>
                </div>

                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text m-0 p-0 h-32">
                      <div className="dropdown">
                        <button
                          className="btn shadow-none dropdown-toggle h-32 pr-05"
                          type="button" id="dropdownMenuStatus"
                          data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {selectedCurrency.name}
                          <Icon type="chevron-mini-down-alt" className="submenu" />
                        </button>
                        <div className="dropdown-menu border-0" aria-labelledby="dropdownMenuStatus">
                          {currencies.map((item) => (
                            <button key={item.currency} className="dropdown-item" type="button" onClick={() => setSelectedCurrency({ name: item.title, currency: item.currency })}>
                              {item.title}
                            </button>
                          ))}

                        </div>
                      </div>
                    </div>
                  </div>
                  <input readOnly hidden name='currency' value={selectedCurrency.currency} />
                  <input type="numeric" title='cpi' min={0.1} step="0.01" id="sampleCpi" name='cpi' className="form-control" defaultValue={batchData.cpi} />
                </div>

                {
                  (errors?.cpi || audienceErrors?.cpi) &&
                  <div className="text-danger">
                    <small>{errors?.cpi}</small>
                    <small>{audienceErrors?.cpi}</small>
                  </div>
                }
              </div>

              <div className="details-input input-group d-flex flex-column w-50 gap-sm">
                <div className='d-flex flex-row justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <p className='p-0 m-0 strong text-primary details-input-text'>
                      Client CPI
                    </p>

                    <Tooltip anchorElement="target" position="top">
                      <span className='icon cursor-pointer' title='Visible to Walr employees'>
                        <Icon type="info-tooltip" className='pe-none' />
                      </span>
                    </Tooltip>
                  </div>
                </div>

                <div className="input-group">
                  <div className="input-group-prepend">
                    <span aria-disabled="true" className="input-group-text medium text-primary strong py-1">{selectedCurrency.name}</span>
                  </div>
                  <input name='clientCPI' type="numeric" title='cpi' min={0.1} step="0.01" className="form-control" id="clientCpi" defaultValue={batchData.clientCPI} />
                </div>

                {
                  (errors?.clientCPI || audienceErrors?.clientCPI) &&
                  <div className="text-danger">
                    <small>{errors?.clientCPI}</small>
                    <small>{audienceErrors?.clientCPI}</small>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-center flex-column w-100'>
          <p className='innerboard-header d-flex align-items-center stronger mb-2 h-32'>
            Metrics
          </p>

          <div className='board-content-container' >
            <div className='row row-col-2 px-1'>
              <div className="details-input d-flex flex-column w-50 gap-md">
                <label className='p-0 m-0 strong text-primary' htmlFor='targetCompletes'>
                  Target completes *
                </label>

                <input id="targetCompletes" className='form-control' type="text" name='quantity' disabled={isStatusClosedOrReconciled} defaultValue={batchData.quantity || ""} />
                {(errors?.quantity || audienceErrors?.quantity) &&
                  <div className="text-danger">
                    <small>{errors?.quantity}</small>
                    <small>{audienceErrors?.quantity}</small>
                  </div>}
              </div>

              <div className="details-input input-group d-flex flex-column w-50 gap-md">
                <label className='p-0 m-0 strong text-primary' htmlFor='targetCompletionLOI'>
                  Target completion LOI *
                </label>

                <div className="input-group">
                  <input className='form-control' type="text" id='targetCompletionLOI' name='lengthOfInterview' disabled={isStatusClosedOrReconciled} defaultValue={batchData.lengthOfInterview ? batchData.lengthOfInterview : ""} />

                  <div className="input-group-append">
                    <span aria-disabled="true" className="input-group-text py-1 time-span">
                      minutes
                    </span>
                  </div>
                </div>

                {(errors?.lengthOfInterview || audienceErrors?.lengthOfInterview) &&
                  <div className="text-danger">
                    <small>{errors?.lengthOfInterview}</small>
                    <small>{audienceErrors?.lengthOfInterview}</small>
                  </div>}
              </div>
            </div>

            <div className='row row-col-2 px-1'>
              <div className="details-input input-group d-flex flex-column w-50 gap-md">
                <label className='p-0 m-0 strong text-primary' htmlFor='targetIncidenceRate'>
                  Target incidence rate *
                </label>

                <div className="input-group">
                  <input className='form-control' type="text" id='targetIncidenceRate' name='incidenceRate' disabled={isStatusClosedOrReconciled} defaultValue={batchData.incidenceRate ? batchData.incidenceRate : ""} />

                  <div className="input-group-append">
                    <span aria-disabled="true" className="input-group-text h-32">%</span>
                  </div>
                </div>

                {(errors?.incidenceRate || audienceErrors?.incidenceRate) &&
                  <div className="text-danger">
                    <small>{errors?.incidenceRate}</small>
                    <small>{audienceErrors?.incidenceRate}</small>
                  </div>}
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-center flex-column w-100'>
          <p className='innerboard-header d-flex align-items-center stronger m-0 h-32'>
            Timeline
          </p>

          <div className='board-content-container'>
            <div className='row row-col-2 px-1'>
              <div className="details-input d-flex flex-column w-50 gap-md">
                <label className='p-0 m-0 strong text-primary' htmlFor='datepickStart'>
                  Start date
                </label>

                <input
                  className='datepicker form-control d-block'
                  type="date"
                  id='datepickStart'
                  min={batchData.batchAction === "edit" ? undefined : getCurrentDate()}
                  max={targetEndDate?.slice(0, 10)}
                  disabled={isStatusClosedOrReconciled}
                  defaultValue={batchData.launchDate ? batchData.batchAction === "copy" ? getCurrentDate() : batchData.launchDate?.slice(0, 10) : getCurrentDate()}
                  name='launchDate'
                  onChange={(e) => setLaunchDate(e.target.value)}
                />
                {(errors?.launchDate || audienceErrors?.launchDate) &&
                  <div className="text-danger">
                    <small>{errors?.launchDate}</small>
                    <small>{audienceErrors?.launchDate}</small>
                  </div>
                }
              </div>
              <div className="details-input d-flex flex-column w-50 gap-md">
                <label className='p-0 m-0 strong text-primary' htmlFor='datepickEnd'>
                  End date
                </label>

                <input
                  className='form-control d-block'
                  type="date"
                  min={launchDate?.slice(0, 10)}
                  disabled={isChecked || isStatusClosedOrReconciled}
                  id='datepickEnd'
                  name='targetEndDate'
                  defaultValue={isChecked ? "" : batchData.targetEndDate ? batchData.targetEndDate?.slice(0, 10) : ""}
                  onChange={(e) => setTargetEndDate(e.target.value)}
                />
                {((errors?.targetEndDate || audienceErrors?.targetEndDate) && !isChecked) &&
                  <div className="text-danger">
                    <small>{errors?.targetEndDate}</small>
                    <small>{audienceErrors?.targetEndDate}</small>
                  </div>
                }
                <div className="customer-dialog d-flex align-items-center flex-row p-0 gap-md">
                  <Switch
                    className="asap-switch"
                    id='adHocCompleteAsap'
                    disabled={isStatusClosedOrReconciled}
                    checked={Boolean(isChecked)}
                    defaultValue={Boolean(isChecked)}
                    onChange={() => setIsChecked(!isChecked)}
                    name='completeAsap'
                    size="small"
                  />

                  <p className="form-check-label d-flex align-items-center">
                    Complete as soon as possible
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Fragment>
  )
}