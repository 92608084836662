import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@progress/kendo-react-tooltip";

import type { RootState } from "../../../store/reducers/rootReducer";
import { Icon } from "../../shared/Icon/Icon";
import type { AudiencesSuppliersData, Batch } from "../../../interfaces/auddineceAccessInterfaces/audiencesTypes";
import { DropdownButton } from "../../shared/DropdownButton/DropdownButton";
import { fetchGetJsonData } from '../../../services/services';
import { useParams } from "react-router-dom";
import { SearchableDropdown } from '../../shared/SearchableDropdown/SearchableDropdown.tsx';


const urls = [
  { name: "Completes", url: "https://welcome.walr.com/surveyout?wid=[%WID%]&status=1" },
  { name: "Screen out", url: "https://welcome.walr.com/surveyout?wid=[%WID%]&status=2" },
  { name: "Quota fail", url: "https://welcome.walr.com/surveyout?wid=[%WID%]&status=3" },
  { name: "Quality fail", url: "https://welcome.walr.com/surveyout?wid=[%WID%]&status=4" }
]

const copyText = "Walr redirect \n Completes: https://welcome-staging.walr.com/surveyout?wid=[%WID%]&status=1 \n Screen out: https://welcome-staging.walr.com/surveyout?wid=[%WID%]&status=2 \n Quota fail: https://welcome-staging.walr.com/surveyout?wid=[%WID%]&status=3 \n Quality fail: https://welcome-staging.walr.com/surveyout?wid=[%WID%]&status=4"

export const SupplierUrlBuilder = () => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { customerId } = useSelector((state: RootState) => state.audienceAdminReducer);

  const params: { projectId: string, audienceId: string, sampleId: string, batchId: string } = useParams();
  const { projectId, sampleId, audienceId } = params;

  const [batches, setBatches] = useState<Batch[]>([]);
  const [suppliersData, setSuppliersData] = useState<AudiencesSuppliersData[]>([]);
  const [selectedSupplier, setSelectedSupplier] = useState<AudiencesSuppliersData>();
  const [selectedBatch, setSelectedBatch] = useState<Batch | null>(null);
  const [searchSupplier, setSearchSupplier] = useState("");
  const [liveUrl, setLiveUrl] = useState<string | null>(null);
  const [testUrl, setTestUrl] = useState<string | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (projectId && sampleId && audienceId) {
      fetchGetJsonData<Batch[]>({ 
        url: `au/a/projects/${projectId}/samples/${sampleId}/audiences/${audienceId}/batches`, 
        token: token, 
        baseUrl: "audience" ,
        customerId
      })
      .then((res) => {
        setBatches(res);
      }).catch((error) => {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } })
      })
    }

    fetchGetJsonData<AudiencesSuppliersData[]>({ 
      url: "au/a/suppliers", 
      token: token,
      baseUrl: "audience",
      customerId
    })
    .then((res) => {
      setSuppliersData(res);
    }).catch((error) => {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } })
    })
  }, [dispatch, token, projectId, sampleId, audienceId, customerId]);

  const onCopyText = (text?: string) => {
    if (text) {
      navigator.clipboard.writeText(text).then(() => {
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The text has been copied to clipboard' } })
      });
    }
  }

  const handleSelectedBatch = (batch: Batch) => {
    setSelectedBatch(batch);
    setLiveUrl(batch.gatekeeperUrl);
    setTestUrl(batch.gatekeeperTestUrl);
    setSelectedSupplier(undefined);
  }

  const setLinkWithSupplier = (supplier: AudiencesSuppliersData) => {
    if (liveUrl && testUrl) {
      setSelectedSupplier(supplier);

      const searchPlatformRegex = /&platform=[^&]+/
      const searchThingRegex = /&recid=[^&]+/

      if (searchPlatformRegex.test(liveUrl) && searchPlatformRegex.test(testUrl)) {
        const liveUrlPlatform = liveUrl?.replace(searchPlatformRegex, `&platform=${supplier.platformId}`);
        const testUrlPlatform = testUrl?.replace(searchPlatformRegex, `&platform=${supplier.platformId}`);

        if (searchThingRegex.test(liveUrlPlatform) && searchThingRegex.test(testUrlPlatform)) {
          const liveUrlPlatformLucid = supplier.name === "Lucid" ? liveUrlPlatform?.replace(searchThingRegex, "&recid=[%RSFN%]") : liveUrlPlatform?.replace(searchThingRegex, "");
          const testUrlPlatformLucid = supplier.name === "Lucid" ? testUrlPlatform?.replace(searchThingRegex, "&recid=[%RSFN%]") : testUrlPlatform?.replace(searchThingRegex, "");

          setLiveUrl(liveUrlPlatformLucid);
          setTestUrl(testUrlPlatformLucid);
        } else {
          setLiveUrl(supplier.name === "Lucid" ? `${liveUrlPlatform}&recid=[%RSFN%]` : liveUrlPlatform);
          setTestUrl(supplier.name === "Lucid" ? `${testUrlPlatform}&recid=[%RSFN%]` : testUrlPlatform);
        }
      }
      else {
        setLiveUrl(`${liveUrl}&platform=${supplier.platformId}`);
        setTestUrl(`${testUrl}&platform=${supplier.platformId}`);
      }
    }
  }

  const handleSupplierSearch = (supplierSearch: string) => {
    setSearchSupplier(supplierSearch.toLowerCase())
  }

  const suppliers = useMemo(() => {
    return suppliersData.filter(({ name }) => name.toLowerCase().includes(searchSupplier.toLowerCase()))
  }, [suppliersData, searchSupplier])


  return (
    <section className="d-flex flex-column p-5 bg-white gap-xxl">
      <article className="d-flex flex-column gap-md">
        <p className="stronger m-0" style={{ fontSize: "1rem" }}>
          Link builder
        </p>

        <div className="d-flex flex-column gap-lg">
          <div className="d-flex gap-md w-100">
            <DropdownButton
              text={selectedBatch ? selectedBatch.displayName : "Batches"}
              textField="displayName"
              className="btn supplier-dropdown"
              items={batches}
              onItemClick={(e) => handleSelectedBatch(e.item)}
              menuPosition="left"
            />

            <SearchableDropdown
              disabled={!selectedBatch}
              text={selectedSupplier ? selectedSupplier.name : "Suppliers"}
              name="suppliers"
              items={suppliers}
              searchFieldPlaceholder="Search suppliers"
              itemIdKey="platformId"
              onSearch={(e) => handleSupplierSearch(e.target.value)}
              onItemClick={(e) => setLinkWithSupplier(e.item)}
              selectedValue=""
              containerClassName="supplier-searchable-dropdown"
            />
          </div>

          <div className='d-flex gap-sm align-items-center'>
            <p className='strong m-0' style={{ width: "4rem" }}>Live Url</p>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <div className="input-group" onClick={() => onCopyText(liveUrl ? liveUrl : "")}>
              <input type='text' className='form-control medium' readOnly value={liveUrl ? liveUrl : ""} />
              <div className="input-group-append">
                <span className="input-group-text h-32">
                  <Icon type="copy" />
                </span>
              </div>
            </div>
          </div>

          <div className='d-flex gap-sm align-items-center'>
            <p className='strong m-0' style={{ width: "4rem" }}>Test url</p>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <div className="input-group" onClick={() => onCopyText(testUrl ? testUrl : "")}>
              <input type='text' className='form-control medium' readOnly value={testUrl ? testUrl : ""} />
              <div className="input-group-append">
                <span className="input-group-text h-32">
                  <Icon type="copy" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article className="d-flex flex-column gap-md">
        <div className="d-flex align-items-center gap-md">
          <p className="stronger m-0" style={{ fontSize: "1rem" }}>
            Walr redirects
          </p>

          <Tooltip anchorElement="target" position="top">
            <button type="button" className="btn btn-secondary h-24" onClick={() => onCopyText(copyText)} title="Copy Walr redirects">
              <Icon type="copy" className="pe-none" />
            </button>
          </Tooltip>
        </div>

        {urls.map((item) =>
          <article className='d-flex align-items-center gap-md' key={item.name}>
            <p className='strong m-0' style={{ width: "5rem" }}>{item.name}</p>
            <div className="input-group">
              <input type='text' className='form-control medium' readOnly defaultValue={item.url} />
            </div>
          </article>
        )}
      </article>
    </section >
  )
}
