import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Checkbox } from '@progress/kendo-react-inputs';

import { Icon } from '../../shared/Icon/Icon';
import type { RootState } from '../../../store/reducers/rootReducer';
import type { Languages, Markets } from '../../../interfaces/auddineceAccessInterfaces/audiencesTypes';
import type { ValidationError } from '../../shared/Zod/handleZodValidation';
import type { audienceSchema } from '../../shared/Zod/ZodSchemas/audienceSchema';
import { Exclusions } from './Exclusions/Exclusions';
import UpdateMarketModal from '../shared/UpdateMarketModal';
import { UniqueUrlsModal } from '../shared/UniqueUrlsModal';
import type { UniqueUrlsModalType } from '../../../interfaces/auddineceAccessInterfaces/audienceOverviewTypes';
import { fetchGetJsonData } from '../../../services/services';
import { UploadRecontactsModal } from '../shared/UploadRecontactsModal';
import { SearchableDropdown } from '../../shared/SearchableDropdown/SearchableDropdown.tsx';

type Props = {
  errors?: ValidationError<typeof audienceSchema>,
  setAudienceName: (name: string) => void;
}

export const AudienceSetupDetails = ({ errors, setAudienceName }: Props) => {
  const audienceData = useSelector((state: RootState) => state.audienceDataReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { customerId } = useSelector((state: RootState) => state.audienceAdminReducer);
  const dispatch = useDispatch();

  const [didMount, setDidMount] = useState(false);
  const [markets, setMarkets] = useState<Markets[]>([]);
  const [searchMarket, setSearchMarket] = useState("");
  const [searchLanguage, setLanguageMarket] = useState("");
  const [selectedMarket, setSelectedMarket] = useState<Markets>(audienceData.market ? { name: audienceData.market.countryName, isoCode: audienceData.market.country } : { name: "", isoCode: "" });
  const [selectedLanguage, setSelectedLanguage] = useState<Languages | null>(audienceData.market ? { name: audienceData.market.languageName, isoCode: audienceData.market.language } : null);
  const [selectedSurveyType, setSelectedSurveyType] = useState(audienceData.surveyType ? audienceData.surveyType : "AdHoc")
  const [isValidLiveUrl, setIsValidLiveUrl] = useState(false);
  const [isValidTestUrl, setIsValidTestUrl] = useState(false);
  const [currentLiveUrl, setCurrentLiveUrl] = useState(audienceData.surveyLiveUrl ? audienceData.surveyLiveUrl : "");
  const [currentTestUrl, setCurrentTestUrl] = useState(audienceData.surveyTestUrl ? audienceData.surveyTestUrl : "");
  const [showExclusions, setShowExclusions] = useState(typeof audienceData?.exclusion === 'object' && audienceData?.exclusion !== null);
  const [showUpdateMarketModal, setShowUpdateMarketModal] = useState(false);
  const [uniqueUrlsModal, setUniqueUrlsModal] = useState<UniqueUrlsModalType>({ show: false, type: undefined, isUniqueUrlEnabled: false });
  const [updatedMarket, setUpdatedMarket] = useState<{ name: string, isoCode: string }>({ name: "", isoCode: "" });
  const [isUniqueUrl, setIsUniqueUrl] = useState(audienceData.audienceAction === "new" ? false : audienceData?.useUniqueUrls);
  const [uniqueUrlLiveFileId, setUniqueUrlLiveFileId] = useState("");
  const [uniqueUrlTestFileId, setUniqueUrlTestFileId] = useState("");
  const [recontactsFileId, setRecontactsFileId] = useState("");
  const [recontactsModal, setRecontactsModal] = useState(false);
  const [incOriginalIdRecontact, setIncOriginalIdRecontact] = useState(audienceData.audienceAction === "new" || audienceData.surveyType === "Recontact");
  const [incWalrIdRecontacts, setIncWalrIdRecontacts] = useState(false);

  const AudienceSurveyTypes = audienceData.audienceAction === "new" ? ["AdHoc", "Tracker", "Recontact"] as const : ["AdHoc", "Tracker"] as const;
  const isEditing = audienceData.audienceAction === "edit"

  const onHide = () => {
    setShowUpdateMarketModal(false);
  };

  useEffect(() => {
    if (!didMount) {
      setDidMount(true)
      fetchGetJsonData<Markets[]>({ 
        url: "au/a/markets?sorted=true", 
        token: token, 
        baseUrl: "audience",
        customerId: customerId
      })
      .then((res) => {
        setMarkets(res);
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } })
      })
    }
  }, [token, didMount, dispatch, customerId])

  const handleMarketSearch = (marketSearch: string) => {
    setSearchMarket(marketSearch.toLowerCase())
  }

  const handleLanguageSearch = (languageSearch: string) => {
    setLanguageMarket(languageSearch.toLowerCase())
  }

  const replacingQueriesInTheUrl = (url: string) => {
    const id = Math.floor(Math.random() * 10000)
    const replaceId = url.replace(/\[%RID%]/, `${id.toString()}_test`)
    const redirectingUrl = replaceId.replace(/\[%PlatformID%]/, "0")

    window.open(redirectingUrl)
    audienceData.surveyLiveUrl && window.open(redirectingUrl)
    audienceData.surveyTestUrl && window.open(redirectingUrl)
  }

  const openURl = (urlType: string) => {
    if (urlType === "liveUrl") {
      audienceData.surveyLiveUrl && replacingQueriesInTheUrl(audienceData.surveyLiveUrl)
    } else {
      audienceData.surveyTestUrl && replacingQueriesInTheUrl(audienceData.surveyTestUrl)
    }
  }

  useEffect(() => {
    try {
      const validateLiveUrl: boolean = Boolean(new URL(currentLiveUrl))
      setIsValidLiveUrl(validateLiveUrl)
    } catch (e) {
      setIsValidLiveUrl(false)
    }
  }, [currentLiveUrl])

  useEffect(() => {
    try {
      const validateTestUrl: boolean = Boolean(new URL(currentTestUrl))
      setIsValidTestUrl(validateTestUrl)
    } catch (e) {
      setIsValidTestUrl(false)
    }
  }, [currentTestUrl])

  const languages = useMemo(() => selectedMarket?.name ? markets.filter((item) => item.name === selectedMarket.name).at(0)?.languages?.filter((language: Languages) => {
    return language.name.toLowerCase().includes(searchLanguage.toLowerCase())
  }) : undefined, [markets, selectedMarket, searchLanguage])

  const filteredMarkets = useMemo(() => markets.filter(market => market.name.toLowerCase().includes(searchMarket)), [markets, searchMarket])

  return (
    <Fragment>
      {
        showUpdateMarketModal &&
        <UpdateMarketModal
          updatedMarket={updatedMarket}
          onHide={onHide}
          setSelectedMarket={setSelectedMarket}
          setSelectedlang={setSelectedLanguage}
        />
      }

      {
        uniqueUrlsModal.show &&
        <UniqueUrlsModal
          setUniqueUrlsModal={setUniqueUrlsModal}
          uniqueUrlsModal={uniqueUrlsModal}
          setUniqueUrlLiveFileId={setUniqueUrlLiveFileId}
          setUniqueUrlTestFileId={setUniqueUrlTestFileId}
        />
      }

      {
        recontactsModal &&
        <UploadRecontactsModal setRecontactsModal={setRecontactsModal} setRecontactsFileId={setRecontactsFileId} />
      }

      <article className='nav-board bg-white d-flex justify-content-center flex-column w-100 p-5'>
        <p className='innerboard-header d-flex align-items-center stronger mb-2 h-32'>Audience setup details</p>
        <div className='board-content-container'>
          <div className='row row-col-2 px-1'>
            <div className="details-input d-flex flex-column w-50 gap-sm">
              <p className='p-0 m-0 strong details-input-text text-primary'>
                Audience name *
              </p>
              <input className='form-control' type="text" id='AudienceName' name='audienceDisplayName' autoComplete='off' defaultValue={audienceData.displayName} onChange={(e) => setAudienceName(e.target.value)} />
              {errors?.displayName &&
                <div className="text-danger">
                  <small>{errors?.displayName}</small>
                </div>
              }
            </div>

            <SearchableDropdown
              disabled={isEditing || audienceData.surveyType === "Recontact"}
              label='Survey type*'
              text={selectedSurveyType}
              itemIdKey='id'
              name='surveyType'
              items={AudienceSurveyTypes.map((item) => ({ id: item, name: item }))}
              errors={errors?.surveyType}
              onItemClick={({ item }) => setSelectedSurveyType(item.name)}
              selectedValue={selectedSurveyType}
              containerClassName='details-input'
            />

            <SearchableDropdown
              disabled={isEditing}
              label='Market'
              text={selectedMarket?.name || "Choose Market"}
              name='country'
              items={filteredMarkets}
              errors={errors?.country}
              searchFieldPlaceholder='Search market'
              itemIdKey='isoCode'
              onSearch={(e) => handleMarketSearch(e.target.value)}
              onItemClick={({ item }) => {
                setSelectedLanguage(null);
                if (audienceData.market?.countryName && selectedMarket.name !== item.name) {
                  setShowUpdateMarketModal(true);
                  setUpdatedMarket(item);
                } else {
                  setSelectedMarket(item);
                  if (item.languages?.length === 1) {
                    setSelectedLanguage(item.languages[0])
                  }
                }
              }}
              selectedValue={selectedMarket.isoCode}
              containerClassName='details-input'
            />

            <SearchableDropdown
              disabled={!selectedMarket.name || isEditing}
              label='Language *'
              text={selectedLanguage?.name || "Choose Language"}
              name='language'
              items={languages}
              errors={errors?.language}
              searchFieldPlaceholder='Search language'
              itemIdKey='isoCode'
              onSearch={(e) => handleLanguageSearch(e.target.value)}
              onItemClick={(e) => setSelectedLanguage(e.item)}
              selectedValue={selectedLanguage?.isoCode || ''}
              containerClassName='details-input'
            />

            <div className="details-input input-group d-flex flex-column w-50 gap-sm">
              <div className='d-flex gap-lg'>
                <div className='d-flex flex-column gap-md h-72'>
                  <div className="d-flex align-items-center gap-md cursor-pointer">
                    <input className='cursor-pointer h-32 w-20' id="liveUrlRadio" name='liveUrlRadio' type="radio" style={{ accentColor: "#172147" }} disabled={audienceData.audienceAction === "new" ? false : audienceData?.useUniqueUrls} checked={!isUniqueUrl} onChange={() => setIsUniqueUrl(!isUniqueUrl)} />
                    <label className="d-flex cursor-pointer m-0 align-items-center align-items-center strong" htmlFor="liveUrlRadio" style={{ whiteSpace: "nowrap" }} >
                      Live URL
                      <Tooltip anchorElement="target" position="top">
                        <span className='icon cursor-pointer' title='[%RID%] to indicate where the Respondent ID should be populated. [%PID%] to indicate where the platform ID should be populated.'>
                          <Icon type="info-tooltip" className='pe-none' />
                        </span>
                      </Tooltip>
                    </label>
                  </div>

                  <div className="d-flex align-items-center gap-md cursor-pointer">
                    <input className='cursor-pointer h-32 w-20' id="batchLiveUrls" name="useUniqueUrls" type="radio" style={{ accentColor: "#172147" }} disabled={audienceData.audienceAction === "new" ? false : !audienceData?.useUniqueUrls} checked={isUniqueUrl} onChange={() => setIsUniqueUrl(!isUniqueUrl)} />
                    <label className="d-flex cursor-pointer m-0 align-items-center align-items-center" htmlFor="batchLiveUrls" style={{ whiteSpace: "nowrap" }}>
                      Unique Links (live)
                    </label>
                  </div>
                </div>

                {
                  isUniqueUrl ?
                    <div className='d-flex gap-md h-72'>
                      <div className='d-flex gap-lg align-items-end'>
                        <button type='button' className='btn btn-primary' onClick={() => setUniqueUrlsModal({ show: true, type: "liveUrl", isUniqueUrlEnabled: audienceData?.useUniqueUrls })}>
                          Upload Live URLs
                        </button>
                        {uniqueUrlLiveFileId && <p className='d-flex align-items-center m-0 mb-1'>Batch url file uploaded <Icon type='checkmark' /></p>}
                        {
                          errors?.uniqueUrlsLiveFileId &&
                          <div className="text-danger">
                            <small>
                              {
                                errors?.uniqueUrlsLiveFileId
                              }
                            </small>
                          </div>
                        }
                      </div>
                      <input hidden readOnly name="uniqueUrlsLiveFileIdVerified" value={uniqueUrlLiveFileId} />
                    </div>
                    :
                    <div className="input-group">
                      <input className='form-control' type="url" id='liveUrl' name='liveUrl' placeholder='https://example.com' onChange={(e) => setCurrentLiveUrl(e.target.value)} value={currentLiveUrl === "" ? "" : currentLiveUrl} />
                      <div className="input-group-append">
                        {isValidLiveUrl &&
                          <div className='input-group-text p-1 h-32' aria-disabled="true">
                            <Tooltip anchorElement="target" position="top">
                              <span title='Valid URL' className='d-flex w-100 h-100' >
                                <Icon className='pe-none' fill="green" type="checkmark" />
                              </span>
                            </Tooltip>
                          </div>
                        }

                        <button type='button' onClick={() => openURl("liveUrl")} aria-disabled="true" className="input-group-text py-1 px-2 h-32">
                          <Tooltip anchorElement="target" position="top">
                            <span className="icon cursor-pointer" title={`${audienceData.surveyLiveUrl ? "Live URL" : "Test URL is not provided"}`}>
                              <Icon type="help-external" fill={audienceData.surveyLiveUrl ? "" : "gray"} className='pe-none' />
                            </span>
                          </Tooltip>
                        </button>
                      </div>

                      {
                        errors?.surveyLiveUrl &&
                        <div className="text-danger">
                          <small>{errors?.surveyLiveUrl}</small>
                        </div>
                      }
                    </div>
                }
              </div>
            </div>

            <div className="details-input d-flex flex-column w-50 gap-sm">
              <div className='d-flex gap-lg'>
                <div className='d-flex flex-column gap-md h-72'>
                  <div className="d-flex align-items-center gap-md cursor-pointer">
                    <input className='cursor-pointer h-32 w-20' id="testUrl" name='testUrlRadio' type="radio" style={{ accentColor: "#172147" }} disabled={audienceData.audienceAction === "new" ? false : audienceData?.useUniqueUrls} checked={!isUniqueUrl} onChange={() => setIsUniqueUrl(!isUniqueUrl)} />
                    <label className="d-flex cursor-pointer m-0 align-items-center align-items-center strong" htmlFor="testUrl" style={{ whiteSpace: "nowrap" }} >
                      Test URL
                      <Tooltip anchorElement="target" position="top">
                        <span className='icon cursor-pointer' title='[%RID%] to indicate where the Respondent ID should be populated. [%PID%] to indicate where the platform ID should be populated.'>
                          <Icon type="info-tooltip" className='pe-none' />
                        </span>
                      </Tooltip>
                    </label>
                  </div>

                  <div className="d-flex align-items-center gap-md cursor-pointer">
                    <input className='cursor-pointer h-32 w-20' id="batchTestUrls" type="radio" style={{ accentColor: "#172147" }} disabled={audienceData.audienceAction === "new" ? false : !audienceData?.useUniqueUrls} checked={isUniqueUrl} onChange={() => setIsUniqueUrl(!isUniqueUrl)} />
                    <label className="d-flex cursor-pointer m-0 align-items-center align-items-center" htmlFor="batchTestUrls" style={{ whiteSpace: "nowrap" }}>
                      Unique Links (test)
                    </label>
                  </div>
                </div>

                {
                  isUniqueUrl ?
                    <div className='d-flex gap-md h-72'>
                      <div className='d-flex gap-lg align-items-end'>
                        <button type='button' className='btn btn-primary' onClick={() => setUniqueUrlsModal({ show: true, type: "testUrl", isUniqueUrlEnabled: audienceData?.useUniqueUrls })}>Upload Test URLs</button>
                        {uniqueUrlTestFileId && <p className='d-flex align-items-center m-0 mb-1'>Batch test url file uploaded <Icon type='checkmark' /></p>}
                        {
                          errors?.uniqueUrlsTestFileId &&
                          <div className="text-danger">
                            <small>
                              {
                                errors?.uniqueUrlsTestFileId
                              }
                            </small>
                          </div>
                        }
                      </div>
                      <input hidden readOnly name="uniqueUrlsTestFileIdVerified" value={uniqueUrlTestFileId} />
                    </div>
                    :
                    <div className="input-group">
                      <div className="input-group">
                        <input id="testURL" className='form-control' type="url" name='testUrl' placeholder='https://example.com' onChange={(e) => setCurrentTestUrl(e.target.value)} value={currentTestUrl === "" ? "" : currentTestUrl} />
                        <div className="input-group-append">
                          {isValidTestUrl &&
                            <div className='input-group-text p-1 h-32' aria-disabled="true">
                              <Tooltip anchorElement="target" position="top">
                                <span title='Valid URL' className='d-flex w-100 h-100' >
                                  <Icon className='pe-none' fill="green" type="checkmark" />
                                </span>
                              </Tooltip>
                            </div>
                          }
                          <button type='button' onClick={() => openURl("testUrl")} aria-disabled="true" className="input-group-text py-1 px-2 h-32">
                            <Tooltip anchorElement="target" position="top">
                              <span className="icon cursor-pointer" title={`${audienceData.surveyTestUrl ? "Test URL" : "Test URL is not provided"}`}>
                                <Icon type="help-external" fill={audienceData.surveyTestUrl ? "" : "gray"} className='pe-none' /></span>
                            </Tooltip>
                          </button>
                        </div>
                      </div>
                      {
                        errors?.surveyTestUrl &&
                        <div className="text-danger">
                          <small>{errors?.surveyTestUrl}</small>
                        </div>
                      }
                    </div>
                }
              </div>
            </div>

            {
              isUniqueUrl &&
              <div className="details-input input-group d-flex flex-column w-50 gap-sm">
                <div className='d-flex align-items-center gap-sm'>
                  <label htmlFor='linkVariable' className="d-flex align-items-center gap-md m-0" style={{ whiteSpace: "nowrap" }}>
                    Link variable:
                    <input id="linkVariable" name='uniqueUrlsWidParam' className='form-control' defaultValue={audienceData?.uniqueUrlsWidParam ? audienceData?.uniqueUrlsWidParam : "WID"} />
                  </label>
                </div>
                {
                  errors?.uniqueUrlsWidParam &&
                  <div className="text-danger">
                    <small>{errors?.uniqueUrlsWidParam}</small>
                  </div>
                }
              </div>
            }

            {
              isUniqueUrl &&
              <div className="details-input input-group d-flex flex-column w-50 gap-sm" />
            }

            <div className="details-input d-flex flex-column w-50 gap-sm">
              <div className='d-flex align-items-center'>
                <p className='p-0 mb-0 text-primary strong details-input-text'>
                  Redirect capture parameter
                </p>
                <Tooltip anchorElement="target" position="top">
                  <span className='icon cursor-pointer' title={"This defines a parameter name that allows the survey host to pass back an additional field on the Walr redirects"}>
                    <Icon type="info-tooltip" className='pe-none' />
                  </span>
                </Tooltip>
              </div>

              <input className='form-control' type="text" id='RedirectCaptureParameter' name='redirectCaptureParameter' placeholder='Optional' defaultValue={audienceData?.redirectCaptureParameter ? audienceData.redirectCaptureParameter : undefined} />
              {errors?.redirectCaptureParameter &&
                <div className="text-danger">
                  <small>{errors?.redirectCaptureParameter}</small>
                </div>}
            </div>

            <div className="details-input d-flex flex-column w-50 px-3 pt-3 pb-1 gap-sm">
              <p className='p-0 mb-0 text-primary strong details-input-text'>
                Does your survey collect personal identifiable information (PII)?
              </p>

              <div className='d-flex flex-row align-items-center gap-md'>
                <div className="d-flex align-items-center gap-md cursor-pointer">
                  <input className='cursor-pointer h-32 w-20' id="containsPersonalDataYes" type="radio" name="containsPersonalData" style={{ accentColor: "#172147" }} defaultChecked={!!audienceData.pii} value="true" />
                  <label className="d-flex cursor-pointer m-0 align-items-center align-items-center" htmlFor="containsPersonalDataYes">
                    Yes
                  </label>
                </div>

                <div className="d-flex align-items-center gap-md cursor-pointer">
                  <input className='cursor-pointer h-32 w-20 text-primary' id="containsPersonalDataNo" type="radio" name="containsPersonalData" style={{ accentColor: "#172147" }} defaultChecked={!audienceData.pii} value="false" />
                  <label className="d-flex cursor-pointer m-0 align-items-center align-items-center" htmlFor="containsPersonalDataNo">
                    No
                  </label>
                </div>
              </div>
            </div>

            <input className='cursor-pointer h-32 w-20' id="Multiple" type="radio" name="panelType" style={{ accentColor: "#172147" }} defaultChecked={true} value="Multiple" hidden />

            <div className="details-input d-flex flex-column w-50 px-3 pt-3 pb-1 gap-sm">
              <div className='d-flex align-items-center'>
                <p className='p-0 mb-0 text-primary strong details-input-text'>
                  Exclusions
                </p>
                <Tooltip anchorElement="target" position="top">
                  <span className='icon cursor-pointer' title='Allows exclusions of respondents who have previously finished a survey with Research Defender enabled'><Icon type="info-tooltip" className='pe-none' /></span>
                </Tooltip>
              </div>

              <div className='d-flex flex-row align-items-center gap-md'>
                <div className="d-flex align-items-center gap-md cursor-pointer">
                  <input
                    className='cursor-pointer h-32 w-20'
                    id="exclusions"
                    type="radio"
                    name="exclusion"
                    style={{ accentColor: "#172147" }}
                    defaultChecked={showExclusions}
                    value="true"
                    onChange={() => setShowExclusions(true)}
                  />
                  <label className="d-flex cursor-pointer m-0 align-items-center align-items-center" htmlFor="exclusions">
                    Yes
                  </label>
                </div>

                <div className="d-flex align-items-center gap-md cursor-pointer">
                  <input
                    className='cursor-pointer h-32 w-20 text-primary'
                    id="no"
                    type="radio"
                    name="exclusion"
                    style={{ accentColor: "#172147" }}
                    defaultChecked={!showExclusions}
                    value="false"
                    onChange={() => setShowExclusions(false)}
                  />
                  <label className="d-flex cursor-pointer m-0 align-items-center align-items-center" htmlFor="no">
                    No
                  </label>
                </div>
              </div>

              {showExclusions && (
                <Exclusions errors={errors} countryCode={selectedMarket.isoCode} showExclusions={showExclusions} />
              )}
            </div>

            <div className="details-input d-flex flex-column w-50 px-3 pt-3 pb-1 gap-sm">
              <div className='d-flex align-items-center'>
                <p className='m-0 text-primary strong details-input-text'>
                  Re-entry
                </p>

                <Tooltip anchorElement="target" position="top">
                  <span className='icon cursor-pointer' title='Selecting this option will allow a respondent to re-entry this link multiple times without being flagged as a duplicate'>
                    <Icon type="info-tooltip" className='pe-none' />
                  </span>
                </Tooltip>
              </div>

              <div className='d-flex flex-row align-items-center gap-md'>
                <div className="d-flex align-items-center gap-md cursor-pointer">
                  <input className='cursor-pointer h-32 w-20' id="allowReentryYes" type="radio" name="allowReentry" style={{ accentColor: "#172147" }} defaultChecked={audienceData?.allowReentry ? audienceData.allowReentry : false} value="true" />
                  <label className="d-flex cursor-pointer m-0 align-items-center align-items-center" htmlFor="allowReentryYes">
                    Yes
                  </label>
                </div>

                <div className="d-flex align-items-center gap-md cursor-pointer">
                  <input className='cursor-pointer h-32 w-20 text-primary' id="allowReentryNo" type="radio" name="allowReentry" style={{ accentColor: "#172147" }} defaultChecked={audienceData?.allowReentry ? audienceData.allowReentry : true} value="false" />
                  <label className="d-flex cursor-pointer m-0 align-items-center align-items-center" htmlFor="allowReentryNo">
                    No
                  </label>
                </div>
              </div>
            </div>

            {selectedSurveyType === "Recontact" &&
              <div className='details-input d-flex flex-column w-100 px-3 pt-3 pb-1 gap-md'>
                <p className='d-flex align-items-center stronger mb-2 h-32'>Recontacts</p>
                <div className='board-content-container d-flex gap-xl'>
                  <div>
                    <button className='btn btn-primary' type='button' onClick={() => setRecontactsModal(true)}>Upload Recontacts</button>
                  </div>

                  <div className='d-flex flex-column gap-md'>
                    <div className='d-flex gap-sm align-items-center h-32'>
                      <label htmlFor="incOriginalIdRecontact" className="d-flex gap-sm align-items-center m-0">
                        <Checkbox id="incOriginalIdRecontact" name='incOriginalIdRecontact' defaultChecked={incOriginalIdRecontact} onChange={() => setIncOriginalIdRecontact(!incOriginalIdRecontact)} />
                        Include original ID
                      </label>

                      <div className='d-flex flex-column'>
                        <div className='d-flex align-items-center gap-sm'>
                          <label htmlFor='originalIdRecontactVariable' className="d-flex gap-sm align-items-center m-0" style={{ whiteSpace: "nowrap" }}>
                            Link variable:
                          </label>
                          <input id="originalIdRecontactVariable" name='originalIdRecontactVariable' className='form-control' defaultValue={audienceData.originalIdRecontactVariable ? audienceData.originalIdRecontactVariable : "old_WID"} />
                        </div>
                        {
                          errors &&
                          <div className="text-danger">
                            <small />
                          </div>
                        }
                      </div>
                    </div>

                    <div className='d-flex gap-sm align-items-center h-32'>
                      <label htmlFor="incWalrIdRecontact" className="d-flex gap-sm align-items-center m-0">
                        <Checkbox id="incWalrIdRecontact" name='incWalrIdRecontact' defaultChecked={incWalrIdRecontacts} onChange={() => setIncWalrIdRecontacts(!incWalrIdRecontacts)} />
                        Include Walr ID
                      </label>
                      <div className='d-flex flex-column'>
                        <div className='d-flex align-items-center gap-sm'>
                          <label htmlFor='walrIdRecontactVariable' className="d-flex gap-sm align-items-center m-0" style={{ whiteSpace: "nowrap" }}>
                            Link variable:
                          </label>
                          <input id="walrIdRecontactVariable" name='walrIdRecontactVariable' className='form-control' defaultValue={audienceData.walrIdRecontactVariable ? audienceData.walrIdRecontactVariable : "WID"} />
                        </div>
                        {
                          errors &&
                          <div className="text-danger">
                            <small />
                          </div>
                        }
                      </div>
                    </div>
                  </div>

                  <input readOnly value={recontactsFileId} hidden name='recontactsFileId' />
                </div>
                {recontactsFileId && <p className='d-flex align-items-center m-0 mb-1'>Recontacts file uploaded <Icon type='checkmark' /></p>}
              </div>
            }

          </div>
        </div>
      </article>
    </Fragment>
  )
}
