import { useState } from "react";
import { useSelector } from "react-redux";

import { Icon } from "../../../../shared/Icon/Icon"
import type { Targeting } from "../../../../../interfaces/auddineceAccessInterfaces/batchOverviewTypes.ts";
import type { Question, TargetingDatas, TargetingResponseData } from "../../types/audienceTargetingTypes"
import type { RootState } from "../../../../../store/reducers/rootReducer";
import { updateSelectedTarget } from "../updateSelectedTarget";


type Props = {
  targetingResponseData: TargetingResponseData | null;
  selectedQuestions: Question[];
  setSelectedQuestions: (item: Question[]) => void;
  setSelectedQuestion: (item: Question | null) => void;
  setSelectedTarget: (item: TargetingDatas | null) => void;
}

type CategoriesObj<T extends string, Question> = {
  [key in T]: Question[];
};

export const QuotaModalCategories = ({ selectedQuestions, targetingResponseData, setSelectedQuestions, setSelectedQuestion, setSelectedTarget }: Props) => {
  const { targeting }: { targeting: Targeting } = useSelector((state: RootState) => state.batchDataReducer);

  const [searchKeyword, setSearchKeyword] = useState("");

  const categoriesObj: CategoriesObj<string, Question> = targetingResponseData?.questions.length ? targetingResponseData.questions.reduce((acc: TODO, cur) => {
    if (!acc[cur.category]) { acc[cur.category] = []; }
    acc[cur.category].push(cur);
    return acc;
  }, {}) : [];

  const categoriesList = categoriesObj && Object.keys(categoriesObj);
  const questionsArr = Object.values(categoriesObj).flat().map((item) => ({ ...item, answers: item.answers }));

  const screeningsId = targeting?.screenings?.map(target => Number(target.questionId));
  const filteredQuestions = screeningsId ? questionsArr.filter((item) => screeningsId?.includes(Number(item.id))) : null;

  const handleSelectQuestion = (question: Question) => {


    const questionToAdd = targetingResponseData?.questions?.find((item) => item.id === question.id);

    if (questionToAdd && selectedQuestions.length === 0) {
      setSelectedQuestions([questionToAdd]);
      setSelectedQuestion(questionToAdd)
      setSelectedTarget(question.answerType === 2 ? updateSelectedTarget(question, targeting) : null)
    } else {
      if (questionToAdd) {
        setSelectedQuestions([...selectedQuestions, questionToAdd]);
        setSelectedQuestion(questionToAdd)
        setSelectedTarget(question.answerType === 2 ? updateSelectedTarget(question, targeting) : null)
      }
    }
  }

  return (
    <div className="d-flex flex-column gap-lg">
      <div className="input-group">
        <input onChange={(e) => setSearchKeyword(e.target.value)} value={searchKeyword} id="searchTargetVariables" type="search" className="form-control search-input" placeholder="Search target variables" />
      </div>
      {
        filteredQuestions ?
          <div
            className="d-flex flex-column gap-md pb-3">
            {filteredQuestions.filter((question) => question.text.toLowerCase().includes(searchKeyword.toLowerCase()) || question.answers.find((answer) => answer.text.toLowerCase().includes(searchKeyword.toLowerCase()))).map((data) =>
              <button
                key={data.id}
                type="button"
                className={`d-flex flex-column quota-accordion-question rounded ${selectedQuestions.find(question => question.id === data.id) && 'disabled'}`}
                onClick={() => handleSelectQuestion(data)}
              >
                <small className='d-flex gap-sm text-disabled'>
                  {data.category === "Custom" ? "Custom " : "Standard"}
                </small>
                <p className="m-0 text-left">{data.text}</p>
              </button>
            )}
          </div>
          :
          searchKeyword
            ?
            <div
              className="d-flex flex-column gap-md pb-3">
              {questionsArr.filter((question) => question.text.toLowerCase().includes(searchKeyword.toLowerCase()) || question.answers.find((answer) => answer.text.toLowerCase().includes(searchKeyword.toLowerCase()))).map((data) =>
                <button
                  key={data.id}
                  type="button"
                  className={`d-flex flex-column quota-accordion-question rounded ${selectedQuestions.find(question => question.id === data.id) && 'disabled'}`}
                  onClick={() => handleSelectQuestion(data)}
                >
                  <small className='d-flex gap-sm text-disabled'>
                    {data.category === "Custom" ? "Custom " : "Standard"}
                  </small>
                  <p className="m-0 text-left">{data.text}</p>
                </button>
              )}
            </div>
            :
            <div className="d-flex flex-column gap-md accordion h-100">
              {categoriesList?.map((categoryName: string, index: number) =>
                <div key={categoryName} className="accordion-item" id={`heading${index}`}>
                  <button
                    className="btn btn-transparent justify-content-start accordion-button collapsed d-flex align-items-center w-100 gap-md"
                    type="button"
                    data-toggle="collapse"
                    data-target={`#collapse${index}`}
                    aria-expanded="false"
                    aria-controls={`collapse${index}`}
                  >
                    <Icon type="audience-folder" />
                    <p className="p-0 m-0 regular strong">{categoryName}</p>
                  </button>

                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse p-1 mt-2"
                    aria-labelledby={`heading${index}`}
                    data-parent={`#collapse${index}`}
                  >

                    {categoriesObj[categoryName].map((item) =>
                      <button
                        key={item.id}
                        type="button"
                        className={`quota-accordion-question mb-2 rounded w-100 text-left ${selectedQuestions?.find(question => question.id === item.id) && 'disabled'}`}
                        onClick={() => handleSelectQuestion(item)}
                      >
                        {item.text}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
      }
    </div>
  )
}