import React from 'react';
import { Icon } from '../Icon/Icon.tsx';

export interface DropdownItem {
  [field: string]: any
}

interface Props {
  name: string
  itemIdKey: string
  text: string
  onItemClick:  (selectedItem: DropdownItem) => void
  selectedValue: string
  items?: DropdownItem[]
  disabled?: boolean
  errors?: string
  label?: string
  searchFieldPlaceholder?: string
  onSearch?:  React.ChangeEventHandler<HTMLInputElement>
  containerClassName?: string
}

export const SearchableDropdown = ({ containerClassName = '', name, errors, label, text, disabled, searchFieldPlaceholder, items, itemIdKey, selectedValue, onSearch, onItemClick } : Props) => {
  return (
    <div className={`${containerClassName} dropdown details-dropdown d-flex flex-column w-50 gap-sm`}>
      {label &&  <p className='p-0 m-0 strong text-primary details-input-text'>{label}</p>}
      <button className="btn dropdown-toggle form-control" type="button" id="dropdownMenuAction" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={disabled}>
        {text}
        <span>
          <Icon type="chevron-mini-down-alt" className="submenu" />
        </span>
      </button>

      <div className="dropdown-menu menu-action" aria-labelledby="dropdownMenuAction">
        {onSearch && (
          <div className='d-flex px-3 pb-3 border-bottom'>
            <input className='form-control' type="text" placeholder={searchFieldPlaceholder} autoComplete='off' id="myInput" onChange={onSearch} />
          </div>
        )}

        <div className='d-flex flex-column overflow-auto dropdown-menu-items'>
          {items?.map((item) => {
            return (
              <button key={item[itemIdKey]} className="dropdown-item" type="button" onClick={() => onItemClick({ item })} >
                {item.name}
              </button>
            )
          })}
        </div>
      </div>

      <input name={name} readOnly hidden value={selectedValue} />

      {errors &&
        <div className="text-danger">
          <small>{errors}</small>
        </div>
      }
    </div>
  )
}