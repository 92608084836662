import { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import { Icon } from "../../../../shared/Icon/Icon";
import type { Targeting } from "../../../../../interfaces/auddineceAccessInterfaces/batchOverviewTypes.ts";
import type { Answer, Question, TargetingDatas } from "../../types/audienceTargetingTypes";
import type { RootState } from "../../../../../store/reducers/rootReducer";
import { DeleteQuotaModal } from "./DeleteQuotaModal";
import { updateSelectedTarget } from "../updateSelectedTarget";

type Props = {
  selectedQuestions: Question[];
  selectedQuestion: Question | null;
  setSelectedQuestion: (item: Question | null) => void;
  setSelectedQuestions: (item: Question[]) => void;
  setSelectedTarget: (item: TargetingDatas | null) => void;
  setSelectedAnswers: (item: Answer[]) => void;
  setSelectAllAnswers: (item: boolean) => void;
}

export const QuotaModalSelectedQuestions = ({ selectedQuestions, selectedQuestion, setSelectedQuestion, setSelectedQuestions, setSelectedTarget, setSelectedAnswers, setSelectAllAnswers }: Props) => {
  const { targeting }: { targeting: Targeting } = useSelector((state: RootState) => state.batchDataReducer);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [questionId, setQuestionId] = useState<string | number>("");

  const handleRemoveSelectedQuestion = (event: TODO, question: Question) => {
    event.stopPropagation();
    const questionToRemove = selectedQuestions.filter((item) => item.id !== question.id);
    if (question.id === selectedQuestion?.id) {
      setSelectedQuestion(null);
    }
    setSelectedQuestions(questionToRemove);
    setSelectedTarget(null);
  }

  const handleSelectedQuestion = (question: Question) => {
    setSelectedQuestion(question);
    if (question.answerType === 2) {
      setSelectedTarget(updateSelectedTarget(question, targeting))
    } else {
      const currentTargeting = targeting?.quotas?.find((item) => item.questionId === question?.id) as unknown as TargetingDatas;
      setSelectedTarget(currentTargeting);
    }

    setSelectedAnswers([]);
    setSelectAllAnswers(false);
  }

  const handleDeleteQuota = (event: TODO, question: Question) => {
    event.stopPropagation();

    setQuestionId(question.id);
    setShowDeleteModal(true);
  }


  return (
    <Fragment>
      {showDeleteModal && <DeleteQuotaModal setShowDeleteModal={setShowDeleteModal} questionId={questionId} setQuestionId={setQuestionId} />}

      {selectedQuestions?.map((question) =>
        // biome-ignore lint/a11y/useKeyWithClickEvents:
        <div
          key={question.id}
          className={`quota-accordion-question d-flex flex-column ${selectedQuestion?.id === question.id ? "bg-secondary" : ""}`}
          onClick={() => handleSelectedQuestion(question)}
        >
          <div className="d-flex w-100 align-items-center justify-content-between">
            <p className="small text-indigo-dark strong m-0">
              {question.category}
            </p>

            {targeting?.quotas?.find((item) => item.questionId === question.id) ?
              <button type='button' className='btn btn-transparent' onClick={(e) => handleDeleteQuota(e, question)}>
                <Icon type="delete-alt" />
              </button>
              : <button type='button' className='btn btn-transparent' onClick={(e) => handleRemoveSelectedQuestion(e, question)}>
                <Icon type="close" />
              </button>}
          </div>

          <p className="large strong m-0 line-clamp-2">
            {question.name}
          </p>
        </div>
      )}
    </Fragment>
  )
}