import { v4 as uuid } from 'uuid';

import type { Targeting } from "../../../../interfaces/auddineceAccessInterfaces/batchOverviewTypes"
import type { Question, TargetingDatas } from "../types/audienceTargetingTypes"

export const updateSelectedTarget = (question: Question, targeting: Targeting | null) => {
  if (!targeting) {
    return null
  }

  const { screenings, quotas } = targeting

  if (screenings && question) {
    const targetingItem = screenings.filter((item) => item.questionId === question.id)[0];
    const currentTargeting = quotas?.find((item) => item.questionId === question.id)
    const updatedTargets = (currentTargeting) ? currentTargeting.targets : targetingItem?.answers.map((item) => {
      return {
        id: uuid(),
        targetCompletes: 0,
        answers: [item]
      }
    })

    return {
      name: targetingItem.name,
      text: targetingItem.text,
      localizedText: question.localizedText,
      questionId: targetingItem.questionId,
      targets: updatedTargets
    } as TargetingDatas
  }

  return null
}